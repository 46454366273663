var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',[(!_vm._.isNil(_vm.summaryError))?_c('v-alert',{attrs:{"dense":"","type":"error"}},[_vm._v(" "+_vm._s(_vm.summaryError)+" ")]):_vm._e(),_c('v-switch',{attrs:{"color":"purple darken-2","label":"Активность"},model:{value:(_vm.form.active),callback:function ($$v) {_vm.$set(_vm.form, "active", $$v)},expression:"form.active"}}),_c('v-select',{attrs:{"readonly":_vm.isUpdate,"error-messages":_vm.getErrors('form.role'),"required":"","disabled":!!_vm.$v.form.role.$model,"items":_vm.roles,"label":"Роль"},model:{value:(_vm.$v.form.role.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.role, "$model", $$v)},expression:"$v.form.role.$model"}}),(!_vm.isRoleStudent)?_c('div',[_c('v-text-field',{attrs:{"error-messages":_vm.getErrors('form.login'),"autocomplete":"new-email","label":"Логин","name":"login","type":"text","placeholder":" ","color":"purple darken-2"},model:{value:(_vm.$v.form.login.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.login, "$model", $$v)},expression:"$v.form.login.$model"}})],1):_vm._e(),(!_vm.isRoleStudent)?_c('div',[_c('v-text-field',{attrs:{"error-messages":_vm.getErrors('form.new_password'),"autocomplete":"new-password","label":"Пароль","name":"new_password","type":"password","placeholder":" ","color":"purple darken-2"},model:{value:(_vm.$v.form.new_password.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.new_password, "$model", $$v)},expression:"$v.form.new_password.$model"}})],1):_vm._e(),(!_vm.isRoleStudent)?_c('div',[_c('v-text-field',{attrs:{"error-messages":_vm.getErrors('form.confirm_password', {
                compare: 'Пароль не совпадает с подтверждением'
            }),"label":"Подтверждение пароля","name":"confirm_password","type":"password","placeholder":" ","color":"purple darken-2"},model:{value:(_vm.$v.form.confirm_password.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.confirm_password, "$model", $$v)},expression:"$v.form.confirm_password.$model"}})],1):_vm._e(),_c('v-text-field',{attrs:{"error-messages":_vm.getErrors('form.name'),"label":"ФИО","name":"name","type":"text","placeholder":" ","autofocus":"autofocus","color":"purple darken-2"},model:{value:(_vm.$v.form.name.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.name, "$model", $$v)},expression:"$v.form.name.$model"}}),(['teacher', 'student'].includes(_vm.queryRole))?[_c('v-divider',{staticClass:"my-4"}),_c('v-select',{class:{'untouchable': !_vm.groups.length},attrs:{"items":_vm.groups,"error-messages":_vm.getErrors('form.group_id'),"menu-props":{ maxHeight: '500' },"label":"Классы","multiple":_vm.form.role === 'teacher',"chips":"","hint":"Выберите классы","persistent-hint":"","color":"purple darken-2","loading":_vm.loadingBars.group},model:{value:(_vm.$v.form.group_id.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.group_id, "$model", $$v)},expression:"$v.form.group_id.$model"}})]:_vm._e(),(_vm.form.role === 'teacher')?_c('div',[_c('div',{staticClass:"pt-4 caption",class:{'red--text': _vm.$v.form.$dirty && !_vm.$v.form.subjects.required }},[_vm._v("Предметы")]),(_vm.$v.form.$dirty && !_vm.$v.form.subjects.required)?_c('div',{staticClass:"caption ls-0 red--text"},[_vm._v("Необходимо выбрать хотя бы 1 предмет.")]):_vm._e(),(_vm.loadingBars.license)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"purple darken-2"}}):_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":[
                { text: 'Предмет', value: 'subject' } ].concat( _vm._.map(_vm._.range(1,12), function (grade) {
                    return { 
                        text: (grade + " кл."),
                        value: ("grade_" + grade), 
                        width: 1, 
                        class: 'text-no-wrap', 
                        align: 'center',
                        sortable: false
                    }
                })
            ),"items":_vm.form.subjectsTable,"items-per-page":100,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.grade_1",fn:function(ref){
            var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"indeterminate":!item.grade_1.show,"disabled":!item.grade_1.show,"color":_vm.$const.color.primary,"ripple":false},model:{value:(item.grade_1.value),callback:function ($$v) {_vm.$set(item.grade_1, "value", $$v)},expression:"item.grade_1.value"}})]}},{key:"item.grade_2",fn:function(ref){
            var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"indeterminate":!item.grade_2.show,"disabled":!item.grade_2.show,"color":_vm.$const.color.primary,"ripple":false},model:{value:(item.grade_2.value),callback:function ($$v) {_vm.$set(item.grade_2, "value", $$v)},expression:"item.grade_2.value"}})]}},{key:"item.grade_3",fn:function(ref){
            var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"indeterminate":!item.grade_3.show,"disabled":!item.grade_3.show,"color":_vm.$const.color.primary,"ripple":false},model:{value:(item.grade_3.value),callback:function ($$v) {_vm.$set(item.grade_3, "value", $$v)},expression:"item.grade_3.value"}})]}},{key:"item.grade_4",fn:function(ref){
            var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"indeterminate":!item.grade_4.show,"disabled":!item.grade_4.show,"color":_vm.$const.color.primary,"ripple":false},model:{value:(item.grade_4.value),callback:function ($$v) {_vm.$set(item.grade_4, "value", $$v)},expression:"item.grade_4.value"}})]}},{key:"item.grade_5",fn:function(ref){
            var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"indeterminate":!item.grade_5.show,"disabled":!item.grade_5.show,"color":_vm.$const.color.primary,"ripple":false},model:{value:(item.grade_5.value),callback:function ($$v) {_vm.$set(item.grade_5, "value", $$v)},expression:"item.grade_5.value"}})]}},{key:"item.grade_6",fn:function(ref){
            var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"indeterminate":!item.grade_6.show,"disabled":!item.grade_6.show,"color":_vm.$const.color.primary,"ripple":false},model:{value:(item.grade_6.value),callback:function ($$v) {_vm.$set(item.grade_6, "value", $$v)},expression:"item.grade_6.value"}})]}},{key:"item.grade_7",fn:function(ref){
            var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"indeterminate":!item.grade_7.show,"disabled":!item.grade_7.show,"color":_vm.$const.color.primary,"ripple":false},model:{value:(item.grade_7.value),callback:function ($$v) {_vm.$set(item.grade_7, "value", $$v)},expression:"item.grade_7.value"}})]}},{key:"item.grade_8",fn:function(ref){
            var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"indeterminate":!item.grade_8.show,"disabled":!item.grade_8.show,"color":_vm.$const.color.primary,"ripple":false},model:{value:(item.grade_8.value),callback:function ($$v) {_vm.$set(item.grade_8, "value", $$v)},expression:"item.grade_8.value"}})]}},{key:"item.grade_9",fn:function(ref){
            var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"indeterminate":!item.grade_9.show,"disabled":!item.grade_9.show,"color":_vm.$const.color.primary,"ripple":false},model:{value:(item.grade_9.value),callback:function ($$v) {_vm.$set(item.grade_9, "value", $$v)},expression:"item.grade_9.value"}})]}},{key:"item.grade_10",fn:function(ref){
            var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"indeterminate":!item.grade_10.show,"disabled":!item.grade_10.show,"color":_vm.$const.color.primary,"ripple":false},model:{value:(item.grade_10.value),callback:function ($$v) {_vm.$set(item.grade_10, "value", $$v)},expression:"item.grade_10.value"}})]}},{key:"item.grade_11",fn:function(ref){
            var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"indeterminate":!item.grade_11.show,"disabled":!item.grade_11.show,"color":_vm.$const.color.primary,"ripple":false},model:{value:(item.grade_11.value),callback:function ($$v) {_vm.$set(item.grade_11, "value", $$v)},expression:"item.grade_11.value"}})]}}],null,true)})],1):_vm._e(),(!_vm._.isNil(_vm.serverErrors) && _vm.serverErrors.length)?_c('v-alert',{staticClass:"mt-5",attrs:{"dense":"","type":"error"}},_vm._l((_vm.serverErrors),function(error,index){return _c('p',{key:index,staticClass:"mb-0"},[_vm._v(_vm._s(error))])}),0):_vm._e(),_c('v-divider',{staticClass:"my-4"}),_c('action-buttons',{attrs:{"waiting-save-and-back":_vm.waiting.save.back,"waiting-save-and-update":_vm.waiting.save.update},on:{"back":_vm.back,"save":_vm.save}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }