<script>
export default {
    methods: {
        async getDataList(module, outputFields, requestFields = []) {
            const { data } = await this.$store.dispatch(`${module}/list`, {
                pagination: 0,
                fields: requestFields.join(',')
            });
            
            return _.map(_.get(data, 'items', []), (item) => {
                return {
                    text: _.get(item, outputFields.label, ''),
                    value: _.get(item, outputFields.value, '')
                }
            });
        }
    }
}
</script>