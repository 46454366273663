export default {
    methods: {
        async retrieveOrLoad (module, action) {

            if (this.$store.state[module].items?.length) { return this.$store.state[module].items }

            const actionKey = `${module}/${action}`

            if (this.$store.state[module]?.loading) {

                await new Promise(resolve => {
                    const intervalId = setInterval(() => {
                        if (this.$store.state[module]?.loading === false) {
                            clearInterval(intervalId)
                            resolve()
                        }
                    }, 250)
                })
            } else {
                await this.$store.dispatch(actionKey, { pagination: 0 });
            }
            
            return this.$store.state[module].items
        }
    }
}